<template>
    <section id="mainContent" class="relative w-screen min-h-screen pointer-events-none lg:flex md:block lg:m-auto lg:p-0 md:pt-10 items-center">
      <div id="contentHolder" class="w-screen dkblue pointer-events-auto">
        <div>
          <h1>Reset your password.</h1>
          <form id="signupForm" @submit.prevent="emailSubmitted">
            <p v-show="responseMsg !== ''">
              <b>Unable to find your login:</b>&nbsp;
              {{ responseMsg }}
            </p>
            <label class="block">
              <input id="email" type="text" class="form-input mt-1 block w-full" placeholder="Email Address" required />
            </label>
            <div class="block mx-0 text-left">
              <button type="submit" value="SUBMIT" class="ml-0 mr-2 btn yellow rounded-sm hover:bg-white">SUBMIT</button>
            </div>
          </form>
        </div>
      </div>
    </section>
</template>

<script>
import axios from 'axios';

export default {
  components: {},
  data() {
    return {
      responseMsg: '',
      loggedIn: false,
    };
  },
  mounted() {

    this.$store.watch(
      (state) => {
        return state.user.authenticated;
      },
      ((newVal, oldVal) => {
        if (newVal !== oldVal) {
          //   this.$forceUpdate();
        }
      }).bind(this),
      {}
    );
  },
  watch: {},
  methods: {
    emailSuccess: function() {
      this.responseMsg = 'Please check your email for a message from us.';
      // console.log('User profile', response.data.user);
      // console.log('User token', response.data.jwt);
      // this.$store.dispatch('setUserStatus', { target: 'authenticated', status: true });
      // this.$store.dispatch('setUserStatus', { target: 'username', status: response.data.user.username });
      // this.$store.dispatch('setUserStatus', { target: 'token', status: response.data.jwt });
      // router.push('/');
    },
    emailError: function(error) {
      console.log(error);
      this.responseMsg = JSON.stringify(error);
    },
    emailSubmitted: function() {
      const email = document.querySelector('#email').value;
      var data = JSON.stringify({ email: email });
      // var data = JSON.stringify({ email: email });
      var config = {
        method: 'post',
        url: this.$store.state.api + 'auth/forgot-password',
        // headers: {
        //   'Content-Type': 'application/json',
        // },
        data: data,
      };

      axios(config)
        .then(this.emailSuccess)
        .catch(this.emailError);
    },
  },
};
</script>
<style lang="scss"></style>
